import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recipe-provider',
  templateUrl: './recipe-provider.component.html',
  styleUrls: ['./recipe-provider.component.css']
})
export class RecipeProviderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
