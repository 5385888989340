import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  base64ToFile,
  ImageCroppedEvent,
  LoadedImage,
} from "ngx-image-cropper";

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.css"],
})
export class ImageUploaderComponent implements OnInit {
  @Input() image: string = "";
  @Input() modalTitle: string = "";
  @Input() imageTitle: string = "";
  @Input() file: Blob | null = null;
  @Input() backgroundColor: string = "";
  @Output() imageChange = new EventEmitter<string>();
  @Output() fileChange = new EventEmitter<Blob>();

  imageChangedEvent: any = "";
  croppedImage: any = "";

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  onImageFileChange(event): void {
    if (event?.target?.files && event?.target?.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.croppedImage = event.target.result as string;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {}

  cropperReady() {}

  loadImageFailed() {}

  saveCroppedImage(): void {
    this.image = this.croppedImage;
    this.file = base64ToFile(this.croppedImage);
    this.modalService.dismissAll();
    this.imageChange.emit(this.image);
    this.fileChange.emit(this.file);
  }

  openModal(modalName: string): void {
    this.modalService.open(modalName);
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }
}
