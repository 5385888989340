import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import swal from "sweetalert2";

@Component({
  selector: "app-ingredients",
  templateUrl: "./ingredients.component.html",
  styleUrls: ["./ingredients.component.css"],
})
export class IngredientsComponent implements OnInit {
  FORM_KEYS = {
    INGREDIENT_NAME: "ingredientName",
    INGREDIENT_QUANTITY: "ingredientQuantity",
    INGREDIENT_UNIT: "ingredientType",
    INGREDIENT_UNIT_OTHER: "ingredientUnitOther",
  };

  addForm: FormGroup;
  editForm: FormGroup;
  VALID_QUANTITY_REGEX: RegExp = new RegExp(
    /^(\d+\.?\/?\d+|\d+(\s\d+\/\d+)?)$/
  );
  editIndex: any = null;

  @Input() ingredients: any = [];
  @Output() ingredientsChange = new EventEmitter<any>();

  UNIT_TYPES: any = [
    { id: 0, ingredientUnitType: "tbsp" },
    { id: 1, ingredientUnitType: "tsp" },
    { id: 2, ingredientUnitType: "ml" },
    { id: 3, ingredientUnitType: "g" },
    { id: 4, ingredientUnitType: "cup" },
    { id: 5, ingredientUnitType: "L" },
    { id: 6, ingredientUnitType: "handful" },
    { id: 7, ingredientUnitType: "sprinkle" },
    { id: 8, ingredientUnitType: "splash" },
    { id: 9, ingredientUnitType: "kg" },
    { id: 10, ingredientUnitType: "whole" },
    { id: 11, ingredientUnitType: "floret" },
    { id: 12, ingredientUnitType: "small" },
    { id: 13, ingredientUnitType: "medium" },
    { id: 14, ingredientUnitType: "large" },
    { id: 15, ingredientUnitType: "bulb" },
    { id: 16, ingredientUnitType: "clove" },
    { id: 17, ingredientUnitType: "bunch" },
    { id: 18, ingredientUnitType: "head" },
    { id: 19, ingredientUnitType: "other" },
  ];

  constructor(private fb: FormBuilder, private modalService: NgbModal) {
    this.addForm = this.fb.group({
      [this.FORM_KEYS.INGREDIENT_NAME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.INGREDIENT_QUANTITY]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.INGREDIENT_UNIT]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.INGREDIENT_UNIT_OTHER]: [""],
    });
    this.editForm = this.fb.group({
      [this.FORM_KEYS.INGREDIENT_NAME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.INGREDIENT_QUANTITY]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.INGREDIENT_UNIT]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.INGREDIENT_UNIT_OTHER]: [""],
    });
    this.handleIngredientUnitValidation();
  }

  handleIngredientUnitValidation(): void {
    const ingredient_add_unit_controller = this.addForm.get(
      this.FORM_KEYS.INGREDIENT_UNIT
    );
    const ingredient_add_other_unit_controller = this.addForm.get(
      this.FORM_KEYS.INGREDIENT_UNIT_OTHER
    );
    const ingredient_edit_unit_controller = this.editForm.get(
      this.FORM_KEYS.INGREDIENT_UNIT
    );
    const ingredient_edit_other_unit_controller = this.editForm.get(
      this.FORM_KEYS.INGREDIENT_UNIT_OTHER
    );

    ingredient_add_unit_controller.valueChanges.subscribe((value) => {
      if (value === "other") {
        ingredient_add_other_unit_controller.setValidators(
          Validators.compose([Validators.required])
        );
      } else {
        ingredient_add_other_unit_controller.setValidators(
          Validators.compose([])
        );
      }
      ingredient_add_other_unit_controller.updateValueAndValidity();
    });

    ingredient_edit_unit_controller.valueChanges.subscribe((value) => {
      if (value === "other") {
        ingredient_edit_other_unit_controller.setValidators(
          Validators.compose([Validators.required])
        );
      } else {
        ingredient_edit_other_unit_controller.setValidators(
          Validators.compose([])
        );
      }
      ingredient_edit_other_unit_controller.updateValueAndValidity();
    });
  }

  numbersCheck(element, type) {
    if (type === "add") {
      this.addForm
        .get(this.FORM_KEYS.INGREDIENT_QUANTITY)
        .patchValue(element.target.value.replace(/[^(\d|\.|\/|\s)]/, ""));
    } else {
      this.editForm
        .get(this.FORM_KEYS.INGREDIENT_QUANTITY)
        .patchValue(element.target.value.replace(/[^(\d|\.|\/|\s)]/, ""));
    }
  }

  addIngredient(): void {
    if (this.validateIngredient("add")) {
      this.ingredients.push({
        ingredientText: this.addForm.get(this.FORM_KEYS.INGREDIENT_NAME).value,
        ingredientCount: this.addForm.get(this.FORM_KEYS.INGREDIENT_QUANTITY)
          .value,
        ingredientUnit:
          this.addForm.get(this.FORM_KEYS.INGREDIENT_UNIT).value === "other"
            ? this.addForm.get(this.FORM_KEYS.INGREDIENT_UNIT_OTHER).value
            : this.addForm.get(this.FORM_KEYS.INGREDIENT_UNIT).value,
        unit: this.addForm.get(this.FORM_KEYS.INGREDIENT_UNIT).value,
      });
      this.addForm.patchValue({
        [this.FORM_KEYS.INGREDIENT_NAME]: "",
        [this.FORM_KEYS.INGREDIENT_QUANTITY]: "",
        [this.FORM_KEYS.INGREDIENT_UNIT]: "",
        [this.FORM_KEYS.INGREDIENT_UNIT_OTHER]: "",
      });
      this.ingredientsChange.emit(this.ingredients);
    }
  }

  validateIngredient(form): boolean {
    if (
      !this.VALID_QUANTITY_REGEX.test(
        form === "add"
          ? this.addForm.get(this.FORM_KEYS.INGREDIENT_QUANTITY).value
          : this.editForm.get(this.FORM_KEYS.INGREDIENT_QUANTITY).value
      )
    ) {
      swal.fire({
        title: "Oops...",
        text: "Quantity is invalid",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return false;
    }
    if (
      (form === "add" && !this.addForm.valid) ||
      (form === "edit" && !this.editForm.valid)
    ) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all fields",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return false;
    }
    return true;
  }

  deleteIngredient(index): void {
    this.ingredients.splice(index, 1);
    this.ingredientsChange.emit(this.ingredients);
  }

  openEditModal(content, index): void {
    this.editIndex = index;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        () => {},
        (reason) => {
          return reason;
        }
      );
    let item = this.ingredients[index];
    this.editForm.patchValue({
      [this.FORM_KEYS.INGREDIENT_NAME]: item?.ingredientText,
      [this.FORM_KEYS.INGREDIENT_QUANTITY]: item?.ingredientCount,
      [this.FORM_KEYS.INGREDIENT_UNIT]: item?.unit,
    });
    if (item?.unit === "other") {
      this.editForm.patchValue({
        [this.FORM_KEYS.INGREDIENT_UNIT_OTHER]: item?.ingredientUnit,
      });
    }
  }

  editIngredient(): void {
    if (this.validateIngredient("edit")) {
      this.ingredients[this.editIndex] = {
        ingredientText: this.editForm.get(this.FORM_KEYS.INGREDIENT_NAME).value,
        ingredientCount: this.editForm.get(this.FORM_KEYS.INGREDIENT_QUANTITY)
          .value,
        ingredientUnit:
          this.editForm.get(this.FORM_KEYS.INGREDIENT_UNIT).value === "other"
            ? this.editForm.get(this.FORM_KEYS.INGREDIENT_UNIT_OTHER).value
            : this.editForm.get(this.FORM_KEYS.INGREDIENT_UNIT).value,
        unit: this.editForm.get(this.FORM_KEYS.INGREDIENT_UNIT).value,
      };

      this.editForm.patchValue({
        [this.FORM_KEYS.INGREDIENT_NAME]: "",
        [this.FORM_KEYS.INGREDIENT_QUANTITY]: "",
        [this.FORM_KEYS.INGREDIENT_UNIT]: "",
        [this.FORM_KEYS.INGREDIENT_UNIT_OTHER]: "",
      });
      this.editIndex = null;
      this.closeModal();
      this.ingredientsChange.emit(this.ingredients);
    }
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  ngOnInit(): void {}
}
