import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  ngOnInit() {
    this.forceReloadAfterTimeout();
  }

  private forceReloadAfterTimeout() {
    const SIX_HOURS_MS = 21600000;

    setTimeout(() => window.location.reload(), SIX_HOURS_MS);
  }
}
