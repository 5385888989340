import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "./../../constants/constants";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  changePassword(payload): Observable<Object> {
    return this.http.post(routes.changePassword, payload);
  }

  getUser(id): Observable<any> {
    return this.http.post(routes.getUser, { userId: id });
  }

  veryifyEmail(email): Observable<any> {
    return this.http.post(routes.verifyEmail, { email });
  }

  updateProfile(payload): Observable<any> {
    return this.http.post(routes.updateProfile, { ...payload });
  }

  uploadImage(file): Observable<any> {
    return this.http.post(routes.uploadProfileImage, file);
  }
}
