import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ImageUploaderComponent } from "./image-uploader.component";
import { ImageCropperModule } from "ngx-image-cropper";

@NgModule({
  imports: [CommonModule, NgbModule, ImageCropperModule],
  declarations: [ImageUploaderComponent],
  exports: [ImageUploaderComponent],
  providers: [],
})
export class ImageUploaderModule {}
