import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from './../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  logOut(payload) : Observable<Object>{
    return this.http.post(routes.logOut,payload);
  } 
}
