import { Component, OnInit } from "@angular/core";
import { TagInputModule } from "ngx-chips";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import swal from "sweetalert2";
import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { IDropdownSettings } from "ng-multiselect-dropdown";

TagInputModule.withDefaults({
  tagInput: {
    placeholder: "Enter New Nutrition List",
    secondaryPlaceholder: "Enter New Nutrition List",
  },
});
import { ToastrService } from "ngx-toastr";
import { DietaryPreferencesService } from "./../../services/dietaryPreferences/dietary-preferences.service";
import { TagManagementService } from "./../../services/tagManagement/tag-management.service";
import { RecipeService } from "./../../services/recipe/recipe.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-add-recipe",
  templateUrl: "./add-recipe.component.html",
  styleUrls: ["./add-recipe.component.css"],
})
export class AddRecipeComponent implements OnInit {
  backgroundcolor: any = "#5998be";
  color: any = "#5998be";
  image: any;
  imageFile: Blob | null = null;
  creatorid: any;
  addRecipeFrm: FormGroup;
  dropdownSettings: IDropdownSettings;
  mealTimeSelectUnselect = [];
  cuisineList: any = [];
  cuisineSelectUnselect = [];
  foodTypeList: any = [];
  foodTypeSelectUnselect = [];
  allHeadList: any = [];
  mealtimeList: any = [];
  dontKnow: any;
  ingredientUnitTypeArray: any = [];
  dontknowValue: boolean = false;
  choosedColor: any = "#61C5BB";
  instructions: any = [];
  dietaryPreferences: any = [];
  ingredients: any = [];
  FORM_KEYS: {
    RECIPE_NAME: string;
    PREPARATION_TIME: string;
    CARBS: string;
    FATS: string;
    PROTEIN: string;
    CALORIES: string;
    SERVING: string;
  };
  constructor(
    private fb: FormBuilder,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private router: Router,
    private toastService: ToastrService,
    private dietaryPreferencesService: DietaryPreferencesService,
    private tagService: TagManagementService,
    private recipeService: RecipeService,
    private loader: NgxUiLoaderService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
    this.FORM_KEYS = {
      RECIPE_NAME: "recipeName",
      PREPARATION_TIME: "preparationTime",
      CARBS: "carbs",
      FATS: "fats",
      PROTEIN: "protein",
      CALORIES: "calories",
      SERVING: "serving",
    };
    this.addRecipeFrm = this.fb.group({
      [this.FORM_KEYS.RECIPE_NAME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.PREPARATION_TIME]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.CARBS]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.FATS]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.PROTEIN]: ["", Validators.compose([Validators.required])],
      [this.FORM_KEYS.CALORIES]: [
        "",
        Validators.compose([Validators.required]),
      ],
      [this.FORM_KEYS.SERVING]: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    var createId = JSON.parse(localStorage.getItem("user"));
    this.creatorid = createId.id;
    this.fetchAllHead();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 2,
      allowSearchFilter: true,
    };
    //this.makeReadyArr();
  }

  get f() {
    return this.addRecipeFrm.controls;
  }

  toggleCheckDontKnow(event) {
    this.dontKnow = event.target.checked;
    if (this.dontKnow == true) {
      this.addRecipeFrm.patchValue({
        [this.FORM_KEYS.CARBS]: 0,
        [this.FORM_KEYS.FATS]: 0,
        [this.FORM_KEYS.PROTEIN]: 0,
        [this.FORM_KEYS.CALORIES]: 0,
      });
      this.dontknowValue = true;
    } else {
      this.addRecipeFrm.patchValue({
        [this.FORM_KEYS.CARBS]: "",
        [this.FORM_KEYS.FATS]: "",
        [this.FORM_KEYS.PROTEIN]: "",
        [this.FORM_KEYS.CALORIES]: "",
      });
      this.dontknowValue = false;
    }
  }

  fetchAllHead() {
    this.tagService.listCategories().subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          this.allHeadList = res["result"];
          for (var i = 0; i < this.allHeadList.length; i++) {
            if (this.allHeadList[i].tagHeadId === "H6") {
              this.headTagMealTime(this.allHeadList[i].id);
            } else if (this.allHeadList[i].tagHeadId === "H4") {
              this.headTagFoodType(this.allHeadList[i].id);
            } else if (this.allHeadList[i].tagHeadId === "H3") {
              this.headTagCusine(this.allHeadList[i].id);
            }
          }
        }
      },
      (err) => {
        throw err;
      }
    );

    this.dietaryPreferencesService.listCategories().subscribe((res) => {
      this.dietaryPreferences = res;
      this.dietaryPreferences.forEach((item, index) => {
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi && !(item.name == "Macronutrient Ratio"),
          textField: "name",
        };
        const diet_control = new FormControl([]);
        this.addRecipeFrm.addControl(item.name, diet_control);
        this.fetchDietaryPreferencesForCategory(item.id, index);
      });
    });
  }

  fetchDietaryPreferencesForCategory(id: string, index: number) {
    this.dietaryPreferencesService.getCategoryOptions(id).subscribe((res) => {
      this.dietaryPreferences[index] = {
        ...this.dietaryPreferences[index],
        options: res,
      };
    });
  }

  async headTagFoodType(Obj) {
    let value = await this.giveTagList(Obj);
    this.foodTypeList = value;
  }

  async headTagMealTime(Obj) {
    let value = await this.giveTagList(Obj);
    this.mealtimeList = value;
  }

  async headTagCusine(Obj) {
    let value = await this.giveTagList(Obj);
    this.cuisineList = value;
  }

  async giveTagList(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.tagService.listTagsByCategoryId(id).subscribe(
        (res) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  colorPicker(e) {
    this.backgroundcolor = e;
    this.color = e;
  }

  async addRecipe() {
    if (!this.addRecipeFrm.valid || this.ingredients.length === 0) {
      swal.fire({
        title: "Oops...",
        text: "Please input all required fields.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (!this.image) {
      swal.fire({
        title: "Oops...",
        text: "Please add a recipe image.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    this.loader.start();

    if (this.imageFile) {
      await this.saveImage();
    }

    let dietary_preferences = {};
    this.dietaryPreferences.forEach((item) => {
      dietary_preferences[item.id] = this.addRecipeFrm
        .get(item.name)
        .value?.map((preference) => preference.id);
    });

    let payload = {
      recipeName: this.addRecipeFrm.get(this.FORM_KEYS.RECIPE_NAME).value,
      creatorId: this.creatorid,
      prepTime: parseInt(
        this.addRecipeFrm.get(this.FORM_KEYS.PREPARATION_TIME).value
      ),
      carbs: this.addRecipeFrm.get(this.FORM_KEYS.CARBS).value,
      fat: this.addRecipeFrm.get(this.FORM_KEYS.FATS).value,
      protein: this.addRecipeFrm.get(this.FORM_KEYS.PROTEIN).value,
      totalCalories: this.addRecipeFrm.get(this.FORM_KEYS.CALORIES).value,
      serving: this.addRecipeFrm.get(this.FORM_KEYS.SERVING).value,
      IsNew: false,
      isSimpleIngredient: false,
      cardColorCode: this.color,
      prepProcess: this.instructions.map((item) =>
        item.prepprocess.replace(/[\r\n]+/gm, "")
      ),
      nutritionalInfo: [],
      ingredients: this.ingredients
        ? this.ingredients.map(
            (item) =>
              item.ingredientCount +
              " " +
              item.ingredientUnit +
              " " +
              item.ingredientText
          )
        : "",
      recipeImage: this.image,
      IDontKnowCalories: this.dontknowValue,
      IngredientList: this.ingredients,
      headTagInfo: [
        {
          headId: this.allHeadList[0].id,
          tags: this.mealTimeSelectUnselect,
        },
        {
          headId: this.allHeadList[3].id,
          tags: this.cuisineSelectUnselect,
        },
        {
          headId: this.allHeadList[2].id,
          tags: this.foodTypeSelectUnselect,
        },
      ],
      dietaryPreferences: dietary_preferences,
    };

    let convToText = JSON.stringify(payload.headTagInfo);
    let srchAndReplace = convToText.split("id").join("tagId");
    let convToJson = JSON.parse(srchAndReplace);
    payload.headTagInfo = convToJson;

    let convToText1 = JSON.stringify(payload.nutritionalInfo);
    let srchAndReplace1 = convToText1.split("id").join("tagId");
    let convToJson1 = JSON.parse(srchAndReplace1);
    payload.nutritionalInfo = convToJson1;

    this.recipeService.addRecipe(payload).subscribe(
      (res) => {
        if (res["serverResponse"].code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/recipe/recipe-list"]);
        }
      },
      (err) => {
        console.log(err);
        swal.fire({
          title: "Oops...",
          text: err?.error?.serverResponse?.message || "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      },
      () => {
        this.loader.stop();
      }
    );
  }

  async saveImage(): Promise<any> {
    return new Promise((resolve, reject) => {
      const fd = new FormData();
      fd.append("file", this.imageFile);
      this.recipeService.uploadImage(fd).subscribe(
        (res) => {
          if (res["serverResponse"].code === 200) {
            this.image = res["result"][0].fileUrl;
            this.toastService.success("Image uploaded successfully", "", {
              positionClass: "toast-top-center",
              closeButton: true,
            });
            resolve(res["result"][0].fileUrl);
          }
        },
        (err) => {
          swal.fire({
            title: "Oops...",
            text:
              err?.error?.serverResponse?.message ||
              "Something went wrong while uploading image",
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loader.stop();
          reject(err);
        }
      );
    });
  }
  save() {
    this.modalService.dismissAll();
  }

  selectColor(event) {
    this.choosedColor = event.target.value;
  }
}
