import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { IngredientsComponent } from "./ingredients.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

@NgModule({
  imports: [CommonModule, NgbModule, ReactiveFormsModule, FormsModule],
  declarations: [IngredientsComponent],
  exports: [IngredientsComponent],
  providers: [],
})
export class IngredientsModule {}
