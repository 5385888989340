import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-recipe-instructions",
  templateUrl: "./recipe-instructions.component.html",
  styleUrls: ["./recipe-instructions.component.css"],
})
export class RecipeInstructionsComponent implements OnInit {
  constructor(private modalService: NgbModal) {}

  @Input() instructions: any = [];
  @Output() instructionsChange = new EventEmitter<any>();

  newInstructionValue: string = "";
  editInstructionValue: string = "";
  editIndex: any = null;

  onNewInstructionChange(text): void {
    if (this.newInstructionValue.length === 0) {
      this.newInstructionValue = this.instructions.length + 1 + ". " + text;
    } else {
      this.newInstructionValue = text;
    }
  }

  addInstruction(): void {
    let instruction = this.newInstructionValue.split(".");
    this.instructions.push({
      prepprocess: this.newInstructionValue.substring(instruction[0].length),
    });
    this.newInstructionValue = "";
    this.instructionsChange.emit(this.instructions);
  }

  deleteInstruction(index): void {
    this.instructions.splice(index, 1);
    this.instructionsChange.emit(this.instructions);
  }

  editInstruction(modal, index): void {
    this.editIndex = index;
    this.editInstructionValue = this.instructions[index].prepprocess
      .replace(/\./g, "")
      .trim();
    this.modalService.open(modal, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
    });
  }

  onEditInstructionSave(): void {
    if (this.editInstructionValue.length > 0) {
      this.instructions[this.editIndex].prepprocess =
        ". " + this.editInstructionValue;
      this.closeModal();
    }
    this.instructionsChange.emit(this.instructions);
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }

  ngOnInit(): void {}
}
