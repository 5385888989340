import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RecipeProviderComponent } from './recipe-provider.component';
import { ProfileComponent } from './';

const routes: Routes = [
  { 
    path: '', 
    component: RecipeProviderComponent,
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecipeProviderRoutingModule { }
