import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "./../../constants/constants";

@Injectable({
  providedIn: "root",
})
export class TagManagementService {
  constructor(private http: HttpClient) {}

  listCategories(): Observable<any> {
    return this.http.post(routes.getTagCategories, {});
  }

  listTagsByCategoryId(id: any): Observable<any> {
    return this.http.post(routes.getTagsByCategoryId, { tagHeadId: id });
  }
}
