import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "./../../constants/constants";

@Injectable({
  providedIn: "root",
})
export class RecipeService {
  constructor(private http: HttpClient) {}

  addRecipe(payload): Observable<any> {
    return this.http.post(routes.addRecipe, { ...payload });
  }

  editRecipe(payload): Observable<any> {
    return this.http.post(routes.editRecipe, { ...payload });
  }

  uploadImage(formData): Observable<any> {
    return this.http.post(routes.uploadRecipeImage, formData);
  }

  viewRecipe(payload): Observable<any> {
    return this.http.post(routes.viewRecipe, { ...payload });
  }
}
