import { Injectable,OnInit  } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { rejects } from 'assert';
import { NgxUiLoaderService } from "ngx-ui-loader"; 

@Injectable({
  providedIn: 'root'
})
export class IoService {
  private baseUrl = environment.baseUrl;
  data_: any;
  userToken: any = '';
  loader: boolean = false;
  icon:any;
  reason:any;
  private messageSource = new BehaviorSubject(' ');
  currentMessage = this.messageSource.asObservable();

  constructor(
    public http: HttpClient,
    private ngxService: NgxUiLoaderService
  ) { }

  // ngOnInit(){
  //   this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
  //   // Stop the foreground loading after 5s
  //   setTimeout(() => {
  //     this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
  //   }, 5000);

  //   // OR
  //   this.ngxService.startBackground("do-background-things");
  //   // Do something here...
  //   this.ngxService.stopBackground("do-background-things");

  //   this.ngxService.startLoader("loader-01"); // start foreground spinner of the loader "loader-01" with 'default' taskId
  //   // Stop the foreground loading after 5s
  //   setTimeout(() => {
  //     this.ngxService.stopLoader("loader-01"); // stop foreground spinner of the loader "loader-01" with 'default' taskId
  //   }, 5000);
  // }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }



  apicall(
    body_params: any,
    request_path: any,
    action_type: String = "post"
  ) {
    return new Promise((resolve,reject) => {

      let url = this.baseUrl + request_path;

      if (action_type == "get") {
        this.ngxService.start();
        this.http.get(url).subscribe(
          data => {
            this.data_ = data;
            this.ngxService.stop();
            resolve(this.data_);
          },
          err => {
            this.ngxService.stop();
            this.data_ = err.error;
            reject(err);
          }
        );
      }

      if (action_type == "post") {
        this.ngxService.start();
        this.http.post(url, body_params).subscribe(
          data => {
           
            this.data_ = data;
             this.ngxService.stop();
            resolve(this.data_);
          },
          err => {
            this.ngxService.stop();
            this.data_ = err.error;
            reject(err);
          }
        );
      }


      if (action_type == "file") {
         this.ngxService.start();
        let headers = new HttpHeaders();

        // headers = headers.set("Content-Type", 'multipart/form-data');
        // headers.append('Accept', 'application/json');
        // headers.append('Access-Control-Allow-Origin ','*');
        // headers.append('Access-Control-Allow-Credentials', 'true');
        // headers.append('Access-Control-Allow-Methods', 'OPTION,POST');
        // let options = new RequestOptions({ headers: headers });

        this.http.post(url, body_params, { headers: headers }).subscribe(
          data => {
             this.ngxService.stop();
            this.data_ = data;
            resolve(this.data_);
          },
          err => {
             this.ngxService.stop();
            this.data_ = err.error;
            resolve(this.data_);
          }
        );
      }

    });
  }

  
}
