import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RecipeComponent } from './recipe.component';
import { AddRecipeComponent, RecipeListComponent,EditRecipeComponent } from './';

const routes: Routes = [
  { 
    path: '', 
    component: RecipeComponent,
    children: [
      {
        path: 'add-recipe', 
        component: AddRecipeComponent,
      },
      {
        path: 'recipe-list', 
        component: RecipeListComponent,
      },
      {
        path: 'edit-recipe',
        component: EditRecipeComponent,
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecipeRoutingModule { }
