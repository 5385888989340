import { environment } from "./../../environments/environment";

export const routes = {
  changePassword: `${environment.baseUrl}creator/change-password`,
  logOut: `${environment.baseUrl}creator/logout`,
  getDietaryPreferencesCategories: `${environment.baseUrl}dietary-preference-categories`,
  getDietaryPreferencesOptions: (categoryId) =>
    `${environment.baseUrl}dietary-preference-categories/${categoryId}/dietary-preferences`,
  getTagCategories: `${environment.baseUrl}tagcategories/fetch-tag-head`,
  getTagsByCategoryId: `${environment.baseUrl}tagcategories/fetch-tag-by-head`,
  addRecipe: `${environment.baseUrl}recipe/add-recipe`,
  editRecipe: `${environment.baseUrl}recipe/edit-recipe`,
  viewRecipe: `${environment.baseUrl}recipe/recipe-view`,
  uploadRecipeImage: `${environment.baseUrl}uploads/recipe-img-upload`,
  getUser: `${environment.baseUrl}creator/creator-profile-view`,
  verifyEmail: `${environment.baseUrl}creator/resend-email`,
  updateProfile: `${environment.baseUrl}creator/creator-profile-update`,
  uploadProfileImage: `${environment.baseUrl}uploads/profile-img-upload`,
};
