import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RecipeRoutingModule } from "./recipe-routing.module";
import { RecipeComponent } from "./recipe.component";
import { AddRecipeComponent } from "./add-recipe/add-recipe.component";
import { RecipeListComponent } from "./recipe-list/recipe-list.component";
import { EditRecipeComponent } from "./edit-recipe/edit-recipe.component";
import { ColorPickerModule } from "ngx-color-picker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { TagInputModule } from "ngx-chips";
import { NgxPaginationModule } from "ngx-pagination";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { IoService } from "../services/http/io.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DecimalpointDirective } from "../validators/decimal.directives";
import { NumberDirective } from "../validators/numbers-only.directives";
import { doubleDirective } from "../validators/double-digit-recipe.directive";
import { ImageUploaderModule } from "../components/image-uploader/image-uploader.module";
import { RecipeInstructionsModule } from "../components/recipe-instructions/recipe-instructions.module";
import { IngredientsModule } from "../components/ingredients/ingredients.module";

@NgModule({
  declarations: [
    RecipeComponent,
    AddRecipeComponent,
    RecipeListComponent,
    EditRecipeComponent,
    DecimalpointDirective,
    NumberDirective,
    doubleDirective,
  ],
  imports: [
    CommonModule,
    RecipeRoutingModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    TagInputModule,
    NgxPaginationModule,
    ImageUploaderModule,
    RecipeInstructionsModule,
    IngredientsModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [ColorPickerModule, DecimalpointDirective],
  providers: [IoService],
})
export class RecipeModule {}
