import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IoService } from '../../services/http/io.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  VerificationFrm: FormGroup;
  storeEmail: any = '';
  message: any;
  closeResult = "";
  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private io: IoService,
    private router: Router,
    private modalService: NgbModal
  ) {

  }

  ngOnInit(): void {
    this.loadFrm();
    this.fetchVerficationCode();
  }

  loadFrm() {
    this.VerificationFrm = this.fb.group({
      code: ['', Validators.compose([Validators.required])]
    })
  }

  fetchVerficationCode() {
    this.ar.queryParams.subscribe((res: any) => {
      if (res) {
        this.VerificationFrm.get('code').setValue(res['code']);
      }
    });
  }

  submit(Obj, content) {
    let payLoad = {
      "encryptedText": Obj.code
    }
    console.log(payLoad);
    this.io.apicall(payLoad, 'creator/verify-email', 'post').then((res: any) => {
      if (res['serverResponse'].code === 200) {
        this.message = this.io.data_.serverResponse.message
        swal.fire({
          title: 'Success',
          text: res['serverResponse']?.message,
          icon: 'success',
          confirmButtonColor: '#442DFF;',
          confirmButtonText: 'ok'
        });
        this.storeEmail = res['result'].userData?.email;
        console.log(this.storeEmail);
        this.router.navigate(['/'])
      }
    }).catch(err => {
      this.message = this.io.data_.serverResponse.message
      if (this.message == 'Internal Server Error') {
        this.modalService
          .open(content, { ariaLabelledBy: "modal-basic-title" })
          .result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              return reason;
            }
          );
      }
      if (this.message != 'Internal Server Error') {
        swal.fire({
          title: 'Oops...',
          text: this.io.data_.serverResponse.message,
          icon: 'warning',
          confirmButtonColor: '#442DFF;',
          confirmButtonText: 'ok'
        });
      }
    })
  }


  closeModal() {
    this.modalService.dismissAll()
  }

}
