import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IoService } from "../../services/http/io.service";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import * as $ from "jquery";
import countries from "../../modal/countries.json";
import { ProfileService } from "./../../services/profile/profile.service";
import { AuthService } from "./../../services/auth/auth.service";
import { ValidatorsService } from "./../../services/validators/validators.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  backgroundcolor: any = "#00ffff7a";
  profileFrm: FormGroup;
  resendverificationFrm: FormGroup;
  passwordChangeFrm: FormGroup;
  userId: any;
  userData: any;
  image: any = "";
  imageFile: any = "";
  countryName: any;
  recipeProviderDetails: any = {};
  email: any;
  storeCountries: any = [];
  countryTelCode: any = "";

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private ProfileService: ProfileService,
    private AuthService: AuthService,
    private ValidatorsService: ValidatorsService,
    private loader: NgxUiLoaderService
  ) {
    this.profileFrm = this.fb.group({
      fullname_: ["", Validators.compose([Validators.required])],
      email_: ["", Validators.compose([Validators.required])],
      phonenumber_: ["", Validators.compose([Validators.required])],
      country: ["", Validators.compose([])],
      refferalweblink_: [
        "",
        Validators.compose([
          Validators.required,
          ValidatorsService.validateUrl,
        ]),
      ],
      instagramlink_: [""],
    });

    this.resendverificationFrm = this.fb.group({
      email_: ["", Validators.compose([Validators.required])],
    });

    this.passwordChangeFrm = this.fb.group({
      currentpassword_: ["", Validators.compose([Validators.required])],
      newpassword_: ["", Validators.compose([Validators.required])],
      confirmpassword_: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem("user"));
    this.userId = this.userData.id;
    this.email = this.userData.email;
    this.profileDetails();
    this.loadCountries();
  }

  get f() {
    return this.profileFrm.controls;
  }
  get f1() {
    return this.resendverificationFrm.controls;
  }
  get f2() {
    return this.passwordChangeFrm.controls;
  }

  profileDetails() {
    this.loader.start();
    this.ProfileService.getUser(this.userId).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          this.recipeProviderDetails = res["result"];
          this.profileFrm.patchValue({
            fullname_: res["result"].name,
            email_: res["result"].email,
            phonenumber_: res["result"].phone,
            country: res["result"].countryCode,
            refferalweblink_: res["result"].referralWebLink
              ? res["result"].referralWebLink
              : "",
            // instagramlink_: res['result'].instagramLink ? res['result'].instagramLink : "",
          });
          this.resendverificationFrm.patchValue({
            email_: res["result"].email,
          });
          this.image = res["result"].profileImage;
          this.countryTelCode = res["result"].countryCode;
          this.countryName = res["result"].countryName;
        }
        this.loader.stop();
      },
      (err) => {
        this.loader.stop();
        throw err;
      }
    );
  }

  verifyEmail() {
    this.loader.start();
    this.ProfileService.veryifyEmail(
      this.recipeProviderDetails.email
    ).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"]?.message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.loader.stop();
        this.profileDetails();
      },
      (err) => {
        this.loader.stop();
        swal.fire({
          title: "Oops....",
          text: err?.error?.serverResponse?.message || "Something went wrong",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  async updateProfile() {
    if (!this.profileFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.profileFrm.markAllAsTouched();
      return;
    }

    this.loader.start();

    if (this.imageFile) {
      await this.saveImage();
    }

    let payload = {
      userId: this.userId,
      name: this.profileFrm.value.fullname_,
      phone: this.profileFrm.value.phonenumber_.toString(),
      countryName: this.countryName,
      countryCode: this.countryTelCode,
      referralWebLink: this.profileFrm.value.refferalweblink_,
      instagramLink:
        this.profileFrm.value.instagramlink_ == null
          ? ""
          : this.profileFrm.value.instagramlink_,
      profileImage: this.image,
    };

    this.ProfileService.updateProfile(payload).subscribe(
      (res) => {
        if (res["serverResponse"].code == 200) {
          swal.fire({
            title: "Success",
            text: res?.serverResponse?.message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.loader.stop();
        this.profileDetails();
      },
      (err) => {
        this.loader.stop();
        swal.fire({
          title: "Oops...",
          text: err?.error?.serverResponse?.message || "something went wrong",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  async saveImage() {
    const formData = new FormData();
    formData.append("file", this.imageFile);
    return new Promise((resolve, reject) => {
      this.ProfileService.uploadImage(formData).subscribe(
        (res) => {
          if (res["serverResponse"].code === 200) {
            this.image = res["result"][0].fileUrl;
            resolve(res["result"][0].fileUrl);
          }
        },
        (err) => {
          this.loader.stop();
          reject(err);
        }
      );
    });
  }

  validateEmail(email_) {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return re.test(String(email_).toLowerCase());
  }

  changePassword(value) {
    if (!value.currentpassword_) {
      swal.fire({
        title: "Oops...",
        text: "Current password is required!",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (!value.newpassword_) {
      swal.fire({
        title: "Oops...",
        text: "New password is required!",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (!value.confirmpassword_) {
      swal.fire({
        title: "Oops...",
        text: "Please confirm password.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (value.newpassword_ !== value.confirmpassword_) {
      swal.fire({
        title: "Oops...",
        text: "Password should be matched",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    let payload = {
      email: this.email,
      oldPassword: this.passwordChangeFrm.value.currentpassword_,
      newPassword: this.passwordChangeFrm.value.newpassword_,
    };
    this.ProfileService.changePassword(payload).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.passwordChangeFrm.reset();
          var token: any = JSON.parse(localStorage.getItem("token"));
          let payload = {
            refreshToken: token.refreshToken,
          };
          this.AuthService.logOut(payload);
          this.route.navigate(["/login"]);
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("recipe");
          localStorage.removeItem("otp");
        }
      },
      ({ error }) => {
        swal.fire({
          title: "Oops...",
          text: error["serverResponse"].message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      }
    );
  }

  loadCountries() {
    this.storeCountries = countries?.countries;
  }

  selectCountry(event) {
    this.countryTelCode = event.target.value;
    const index = this.storeCountries.findIndex((e) => {
      return e.code == event.target.value;
    });
    this.countryName = this.storeCountries[index].name;
  }

  showHide() {
    $(".showHide-password").each(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  showHide1() {
    $(".current-password").each(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  showHide2() {
    $(".confirm-password").each(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }
}
