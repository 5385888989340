import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { forkJoin, Subscription } from "rxjs";
import { DietaryPreferencesService } from "./../../services/dietaryPreferences/dietary-preferences.service";
import { TagManagementService } from "./../../services/tagManagement/tag-management.service";
import { RecipeService } from "./../../services/recipe/recipe.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-edit-recipe",
  templateUrl: "./edit-recipe.component.html",
  styleUrls: ["./edit-recipe.component.css"],
})
export class EditRecipeComponent implements OnInit, OnDestroy {
  allHeadList = [];
  backgroundcolor: any = "#5998be";
  color: any = "#5998be";
  image: any;
  imageFile: Blob | null = null;
  closeResult = "";
  recipeId: string;
  recipeViewResult: any;
  creatorid: any;
  isActive: any;
  isEdited: any;
  isPublished: any;
  dropdownSettings: IDropdownSettings;
  mealTimeList: any = [];
  mealTimeSelectUnselect = [];
  cuisineList: any = [];
  cuisineSelectUnselect = [];
  foodTypeList: any = [];
  foodTypeSelectUnselect = [];
  editRecipeFrm: FormGroup;
  isSimpleIngredientCheck: any;
  choosedColor: any = "#61C5BB";
  dontKnow: any;
  dontknowValue: boolean = false;
  paramObserver: Subscription;
  dietaryPreferences: any = [];
  instructions: any = [];
  ingredients: any = [];
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private dietaryPreferencesService: DietaryPreferencesService,
    private tagManagementService: TagManagementService,
    private recipeService: RecipeService,
    private loader: NgxUiLoaderService,
    private toastService: ToastrService
  ) {
    this.editRecipeFrm = this.fb.group({
      recipeName_: ["", Validators.compose([Validators.required])],
      preparationTime_: ["", Validators.compose([Validators.required])],
      carbs_: ["", Validators.compose([Validators.required])],
      fat_: ["", Validators.compose([Validators.required])],
      protin_: ["", Validators.compose([Validators.required])],
      calories_: ["", Validators.compose([Validators.required])],
      color: ["", Validators.compose([Validators.required])],
      serving_: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    var createId = JSON.parse(localStorage.getItem("user"));
    this.creatorid = createId.id;
    this.paramObserver = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.recipeId = id;
      } else {
        this.router.navigate(["/recipe/recipe-list"]);
      }
    });
    this.fetchAllHead();
    this.dropdownSettings = {
      singleSelection: false,
      idField: "tagId",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  editRecipelist() {
    this.loader.start();
    let payLoad = {
      recipeId: this.recipeId,
    };
    this.recipeService.viewRecipe(payLoad).subscribe(
      (res) => {
        if (res["serverResponse"].code === 200) {
          this.recipeViewResult = res["result"];
          this.isSimpleIngredientCheck = res["result"]?.isSimpleIngredient;
          this.editRecipeFrm.patchValue({
            recipeName_: this.recipeViewResult.recipeName,
            preparationTime_: this.recipeViewResult.prepTime,
            carbs_: this.recipeViewResult.carbs,
            fat_: this.recipeViewResult.fat,
            protin_: this.recipeViewResult.protein,
            calories_: this.recipeViewResult.totalCalories,
            //ingredient_: this.recipeViewResult.IngredientList,
            serving_: this.recipeViewResult.serving,
            // preparationProcess_: this.recipeViewResult.prepProcess,
            color: this.recipeViewResult.cardColorCode,
          });
          this.isActive = this.recipeViewResult.isActive;
          this.isPublished = this.recipeViewResult.isPublished;
          this.isEdited = this.recipeViewResult.isEdited;
          this.dontknowValue = this.recipeViewResult.IDontKnowCalories;
          this.image = this.recipeViewResult.recipeImage;

          this.color = this.recipeViewResult.cardColorCode;

          this.mealTimeSelectUnselect = [
            ...this.recipeViewResult.headTagInfo[2].tags,
          ];
          this.cuisineSelectUnselect = [
            ...this.recipeViewResult.headTagInfo[3].tags,
          ];
          this.foodTypeSelectUnselect = [
            ...this.recipeViewResult.headTagInfo[4].tags,
          ];

          for (var i = 0; i < this.recipeViewResult.prepProcess.length; i++) {
            this.instructions.push({
              prepprocess: this.recipeViewResult.prepProcess[i].replace(
                /[\r\n]+/gm,
                ""
              ),
            });
          }

          for (
            var i = 0;
            i < this.recipeViewResult.IngredientList.length;
            i++
          ) {
            this.ingredients.push({
              ingredientText:
                this.recipeViewResult.IngredientList[i].ingredientText,
              ingredientCount:
                this.recipeViewResult.IngredientList[i].ingredientCount,
              ingredientUnit:
                this.recipeViewResult.IngredientList[i].ingredientUnit,
              unit: this.recipeViewResult.IngredientList[i].unit,
            });
          }
          this.dietaryPreferences.forEach((item) => {
            if (this.recipeViewResult?.dietaryPreferences[item?.id]) {
              let selection = this.recipeViewResult?.dietaryPreferences[
                item?.id
              ].map((mapItem) => {
                let searchRes = item.options?.find(
                  (searchItem) => searchItem.id == mapItem
                );
                if (searchRes) {
                  return { id: searchRes?.id, name: searchRes.name };
                }
              });
              this.editRecipeFrm.patchValue({ [item.name]: selection });
            }
          });
        }
      },
      (err) => {
        throw err;
      },
      () => {
        this.loader.stop();
      }
    );
  }

  toggleCheckSimpleIngidients(event) {
    this.isSimpleIngredientCheck = event.target.checked;
  }

  toggleCheckDontKnow(event) {
    this.dontKnow = event.target.checked;
    if (this.dontKnow == true) {
      this.editRecipeFrm.patchValue({
        carbs_: 0,
        fat_: 0,
        protin_: 0,
        calories_: 0,
      });
      this.dontknowValue = true;
    } else {
      this.editRecipeFrm.patchValue({
        carbs_: "",
        fat_: "",
        protin_: "",
        calories_: "",
      });
      this.dontknowValue = false;
    }
  }

  fetchAllHead() {
    const tag_subscriber = this.tagManagementService.listCategories();
    const diet_subscriber = this.dietaryPreferencesService.listCategories();
    forkJoin({
      tagRes: tag_subscriber,
      dietRes: diet_subscriber,
    }).subscribe(async ({ tagRes, dietRes }) => {
      let categories_subscribers: any = {};
      if (tagRes["serverResponse"].code === 200) {
        this.allHeadList = tagRes["result"];
        for (var i = 0; i < this.allHeadList.length; i++) {
          if (this.allHeadList[i].tagHeadId === "H6") {
            this.headTagMealTime(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H4") {
            this.headTagFoodType(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H3") {
            this.headTagCusine(this.allHeadList[i].id);
          }
        }
      }
      this.dietaryPreferences = dietRes;
      this.dietaryPreferences.forEach((item) => {
        categories_subscribers[item?.id] =
          this.dietaryPreferencesService.getCategoryOptions(item.id);
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi && !(item.name == "Macronutrient Ratio"),
          textField: "name",
          idField: "id",
        };
        const diet_control = new FormControl([]);
        this.editRecipeFrm.addControl(item.name, diet_control);
      });
      forkJoin(categories_subscribers).subscribe((data) => {
        this.handleDietaryPreferencesCategories(data);
      });
    });
  }
  handleDietaryPreferencesCategories(data) {
    this.dietaryPreferences.forEach((item, index) => {
      item.options = data[item.id];
    });

    this.editRecipelist();
  }

  async headTagFoodType(Obj) {
    let value = await this.giveTagList(Obj);
    let myArr2 = [];
    for (let tags of value) {
      myArr2.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.foodTypeList = myArr2;
  }

  async headTagMealTime(Obj) {
    let value = await this.giveTagList(Obj);
    let myArr4 = [];
    for (let tags of value) {
      myArr4.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.mealTimeList = myArr4;
  }

  async headTagCusine(Obj) {
    let value = await this.giveTagList(Obj);
    let myArr5 = [];
    for (let tags of value) {
      myArr5.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.cuisineList = myArr5;
  }

  async giveTagList(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.tagManagementService.listTagsByCategoryId(id).subscribe(
        (res) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  colorPicker(e) {
    this.backgroundcolor = e;
  }

  onChange(e) {}

  async editRecipe() {
    if (!this.editRecipeFrm.valid || this.ingredients.length === 0) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    this.loader.start();

    if (this.imageFile) {
      await this.saveImage();
    }

    let dietary_preferences = {};
    this.dietaryPreferences.forEach((item) => {
      dietary_preferences[item.id] = this.editRecipeFrm
        .get(item.name)
        .value?.map((preference) => preference.id);
    });

    let payload = {
      recipeId: this.recipeId,
      recipeName: this.editRecipeFrm.value.recipeName_,
      creatorId: this.creatorid,
      prepTime: parseInt(this.editRecipeFrm.value.preparationTime_),
      carbs: this.editRecipeFrm.value.carbs_,
      fat: this.editRecipeFrm.value.fat_,
      protein: this.editRecipeFrm.value.protin_,
      totalCalories: this.editRecipeFrm.value.calories_,
      IsNew: false,
      isPublished: false,
      isSimpleIngredient: this.isSimpleIngredientCheck,
      serving: this.editRecipeFrm.value.serving_,
      nutritionalInfo: [],
      ingredients: this.ingredients
        ? this.ingredients.map(
            (item) =>
              item.ingredientCount +
              " " +
              item.ingredientUnit +
              " " +
              item.ingredientText
          )
        : "",
      cardColorCode: this.color,
      prepProcess: this.instructions.map((item) =>
        item.prepprocess.replace(/[\r\n]+/gm, "")
      ),
      recipeImage: this.image,
      IDontKnowCalories: this.dontknowValue,
      IngredientList: this.ingredients,
      headTagInfo: [
        {
          headId: this.allHeadList[0].id,
          tags: this.mealTimeSelectUnselect,
        },
        {
          headId: this.allHeadList[3].id,
          tags: this.cuisineSelectUnselect,
        },
        {
          headId: this.allHeadList[2].id,
          tags: this.foodTypeSelectUnselect,
        },
      ],
      dietaryPreferences: dietary_preferences,
    };

    if (this.isPublished == true) {
      payload["isEdited"] = true;
    }

    this.recipeService.editRecipe(payload).subscribe(
      (res) => {
        if (res["serverResponse"].code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/recipe/recipe-list"]);
        }
      },
      (err) => {
        swal.fire({
          title: "Oops...",
          text: err?.error?.serverResponse?.message || "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      },
      () => {
        this.loader.stop();
      }
    );
  }

  async saveImage() {
    this.loader.start();
    const fd = new FormData();
    fd.append("file", this.imageFile);
    return new Promise((resolve, reject) => {
      this.recipeService.uploadImage(fd).subscribe(
        (res) => {
          if (res["serverResponse"].code === 200) {
            this.image = res["result"][0].fileUrl;
            this.toastService.success("Image uploaded successfully", "", {
              positionClass: "toast-top-center",
              closeButton: true,
            });
          }
          resolve(res["result"][0]?.fileUrl);
        },
        (err) => {
          swal.fire({
            title: "Oops...",
            text:
              err?.error?.serverResponse?.message || "Something went wrong!",
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loader.stop();
          reject(err);
        }
      );
    });
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: "image/png",
    });
  }

  save() {
    this.modalService.dismissAll();
  }

  selectColor(event) {
    this.choosedColor = event.target.value;
  }
}
