import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthguardService } from './guards/authguard.service';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';

const routes: Routes =[
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }, 
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
          path: '',
          loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
          canActivate:[AuthguardService]
        }
      ]
    },
  { 
    path: '', 
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) 
  },

  {
    path: 'verifyEmail',
    component: VerifyEmailComponent,
  },
  { 
    path: 'recipe', 
    loadChildren: () => import('./recipe/recipe.module').then(m => m.RecipeModule),
    canActivate: [AuthguardService] 
  },
  { 
    path: 'recipe-provider', 
    loadChildren: () => import('./recipe-provider/recipe-provider.module').then(m => m.RecipeProviderModule),
    canActivate: [AuthguardService]
  },
  // { 
  //   path: '', 
  //   loadChildren: () => import('./general/general.module').then(m => m.GeneralModule) 
  // },
  {
    path: '**',
    redirectTo: 'dashboard',
    canActivate: [AuthguardService]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
