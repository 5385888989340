import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ValidatorsService {
  constructor() {}
  validateUrl(formControl) {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  if (pattern.test(formControl.value)) {
    return null;
  }
  else{
      return { validUrl: true };
  }
  }
}
