import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IoService } from "../../services/http/io.service";
import swal from "sweetalert2";
import { Subscription } from "rxjs";
let closeResult = "";

@Component({
  selector: "app-recipe-list",
  templateUrl: "./recipe-list.component.html",
  styleUrls: ["./recipe-list.component.css"],
})
export class RecipeListComponent implements OnInit, OnDestroy {
  selectedValue: number;
  recipelist: any = [];
  page: number;
  creatorid: any;
  recipeid: any;
  recipeName: any;
  name: any;
  recipeItem: any = "";
  total: number;
  itemsPerPage: number = 50;
  recipeCountAllItem: any = {};
  checkingData: boolean = false;
  isSearch: boolean = false;
  paramObserver: Subscription;

  constructor(
    private modalService: NgbModal,
    private ioservice: IoService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    var userData = JSON.parse(localStorage.getItem("user"));
    this.creatorid = userData.id;
    this.name = userData.name;
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let currentPage = values.page;
      let search = values.search;
      if (currentPage) {
        this.page = currentPage;
      } else {
        this.page = 1;
      }
      if (search) {
        this.isSearch = true;
        this.recipeItem = search;
        this.checkingData = true;
      }
      this.recipeListByAll();
      this.recipeCount();
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  delete(content, id, name) {
    this.recipeid = id;
    this.recipeName = name;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", centered: true })
      .result.then(
        (result) => {
          closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  countStar(star) {
    this.selectedValue = star;
  }

  recipeListByAll() {
    this.recipelist = [];
    let payload;
    if (this.recipeItem == "") {
      payload = {
        limit: this.itemsPerPage,
        page: this.page,
        creatorId: this.creatorid,
        recipeName: this.isSearch ? this.recipeItem : (this.recipeItem = ""),
        ingredients: "",
        nutritionalInfo: "",
        orderName: "recipeName",
        orderType: 1,
      };
    } else {
      payload = {
        page: this.page,
        limit: this.itemsPerPage,
        creatorId: this.creatorid,
        recipeName: this.isSearch ? this.recipeItem : (this.recipeItem = ""),
        ingredients: "",
        nutritionalInfo: "",
        orderName: "recipeName",
        orderType: 1,
      };
    }

    this.ioservice
      .apicall(payload, "recipe/recipe-list-by-creator", "post")
      .then((res) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          // this.checkingData = false;
          this.total = result.totalRecords;
          if (this.total > this.recipelist.length) {
            this.recipelist = [...this.recipelist, ...result.recipeList];
          }

          // this.recipeItem = '';
          // this.checkingData = true;
        }
      });
  }

  pageChanged(event) {
    if (event >= 1) {
      this.recipeListByAll();
    }
  }

  deleteRecipe() {
    let payload = {
      recipeId: this.recipeid,
    };
    this.ioservice
      .apicall(payload, "recipe/del-recipe", "post")
      .then((res) => {
        if (res["serverResponse"].code === 200) {
          this.modalService.dismissAll();
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        this.recipelist = [];
        this.recipeListByAll();
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.ioservice.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  searchRecipe() {
    this.isSearch = true;
    this.page = 1;
    this.handleParams(1, this.recipeItem);
    let payload = {
      page: this.page,
      limit: this.itemsPerPage,
      creatorId: this.creatorid,
      recipeName: this.recipeItem,
      ingredients: "",
      nutritionalInfo: "",
      orderName: "recipeName",
      orderType: 1,
    };
    this.ioservice
      .apicall(payload, "recipe/recipe-list-by-creator", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          this.checkingData = true;
          this.recipelist = result.recipeList;
          this.total = result.totalRecords;
          if (this.recipelist.length === 0) {
            this.recipeListByAll();
            this.recipeCount();
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  clearSearchRecipe() {
    this.recipeItem = "";
    this.isSearch = false;
    this.checkingData = false;
    this.page = 1;
    this.handleParams(1, null);
    this.recipeListByAll();
  }

  recipeCount() {
    let payload = {
      userId: this.creatorid,
    };
    this.ioservice
      .apicall(payload, "recipe/total-recipe-count", "post")
      .then((res) => {
        if (res["serverResponse"].code === 200) {
          this.recipeCountAllItem = res["result"];
        }
      });
  }

  handleParams(page, search): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page, search },
      queryParamsHandling: "merge",
    });
  }
}
