import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastService: ToastrService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token: string = JSON.parse(localStorage.getItem("token"));
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token,
          apiversion: environment.httpHeaders.apiVersion,
          channel: environment.httpHeaders.channel,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          apiversion: environment.httpHeaders.apiVersion,
          channel: environment.httpHeaders.channel,
        },
      });
    }
    return next.handle(request).pipe(
      tap(
        (event) => (event instanceof HttpResponse ? "succeeded" : ""),
        (error) => {
          if (error instanceof HttpErrorResponse) {
            let statusCde = error.status;
            if (statusCde === 401 || statusCde === 403) {
              const token = localStorage.getItem('token');

              if (token) {
                localStorage.clear();
                window.location.href = '/';
              }
            }
          }
        }
      )
    );
  }
}
