import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IoService } from '../../services/http/io.service';
import swal from 'sweetalert2'

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/recipe-provider/profile', title: 'Profile', icon: 'users_single-02', class: '' },
  //{ path: '/dashboard', title: 'Dashboard',  icon: 'design_app', class: '' },
  // { path: '/icons', title: 'Icons',  icon:'education_atom', class: '' },
  { path: '/recipe/recipe-list', title: 'Recipe Management', icon: 'clothes_tie-bow', class: '' },

  //{ path: '/recipe/add-recipe', title: 'Add Recipe',  icon:'business_money-coins', class: '' },
  // { path: '/maps', title: 'Maps',  icon:'location_map-big', class: '' },
  // { path: '/notifications', title: 'Notifications',  icon:'ui-1_bell-53', class: '' },
  // { path: '/user-profile', title: 'User Profile',  icon:'users_single-02', class: '' },
  // { path: '/table-list', title: 'Table List',  icon:'design_bullet-list-67', class: '' },
  // { path: '/typography', title: 'Typography',  icon:'text_caps-small', class: '' }
  { path: '/login', title: 'Logout', icon: 'now-ui-icons media-1_button-power', class: '' },

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private router: Router, private ioservice: IoService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  };

  logOut() {
    let token = JSON.parse(localStorage.getItem('refreashToken'));
    console.log(token);
    let payload = {
      "refreshToken": token
    }
    this.ioservice.apicall(payload, 'creator/logout', 'post').then(res => {
      if (this.ioservice.data_.serverResponse.code == 200) {
        this.router.navigate(['/login']);
        localStorage.clear();
      }
    }).catch(err => {
      localStorage.clear();
      this.router.navigate(['/login']);
      swal.fire({
        title: 'Oops...',
        text: this.ioservice.data_.serverResponse.message,
        icon: 'warning',
        confirmButtonColor: '#442DFF;',
        confirmButtonText: 'ok'
      });
    })

  }
}
