import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RecipeProviderRoutingModule } from "./recipe-provider-routing.module";
import { RecipeProviderComponent } from "./recipe-provider.component";
import { ProfileComponent } from "./profile/profile.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ImageUploaderModule } from "../components/image-uploader/image-uploader.module";

@NgModule({
  declarations: [RecipeProviderComponent, ProfileComponent],
  imports: [
    CommonModule,
    RecipeProviderRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    ImageUploaderModule,
    NgbModule,
  ],
})
export class RecipeProviderModule {}
