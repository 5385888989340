import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { routes } from "./../../constants/constants";

@Injectable({
  providedIn: "root",
})
export class DietaryPreferencesService {
  constructor(private http: HttpClient) {}

  listCategories(): Observable<any> {
    return this.http.get(routes.getDietaryPreferencesCategories);
  }

  getCategoryOptions(id): Observable<any> {
    return this.http.get(routes.getDietaryPreferencesOptions(id));
  }
}
